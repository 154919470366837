// extracted by mini-css-extract-plugin
export var support = "Support-module--support--1zWkL";
export var content = "Support-module--content--3kJ7G";
export var container = "Support-module--container--1xvsy";
export var paragraph = "Support-module--paragraph--3Wc9B";
export var title = "Support-module--title--22fRO";
export var subtitle = "Support-module--subtitle--1jLOF";
export var description = "Support-module--description--25A17";
export var description_desktop = "Support-module--description_desktop--3nhqV";
export var descriptionGray = "Support-module--descriptionGray--357Un";
export var descriptionGray_desktop = "Support-module--descriptionGray_desktop--3NwDT";
export var link = "Support-module--link--36gMt";
export var thirdTitle = "Support-module--thirdTitle--1pHyu";
export var inputContainer = "Support-module--inputContainer--1zoal";
export var DropdownControl = "Support-module--Dropdown-control--21kMu";
export var textareaContainer = "Support-module--textareaContainer--3rwwT";
export var submitButtonContainer = "Support-module--submitButtonContainer--3nDj1";
export var submitButton = "Support-module--submitButton--36Ghy";
export var submitButtonSubmitted = "Support-module--submitButtonSubmitted--2pFKI";
export var checkWhite = "Support-module--checkWhite--1pZa0";
export var benefitsContainer = "Support-module--benefitsContainer--3MqDQ";
export var benefit = "Support-module--benefit--1pBkJ";
export var benefitIcon = "Support-module--benefitIcon--2nZnm";
export var benefitTitle = "Support-module--benefitTitle--2xv79";
export var benefitDescription = "Support-module--benefitDescription--2gVVr";
export var buttonToTop = "Support-module--buttonToTop--bMdDw";
export var buttonToTopHidden = "Support-module--buttonToTopHidden--2f0hh";
export var footerContainer = "Support-module--footerContainer--3cB3H";