import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from "@merchx-v2/helpers";
import { Link, graphql } from "gatsby";
import { useSiteMetadata } from "hooks/use-site-metadata";
import emailSvg from "assets/email.svg";
import chatSvg from "assets/chat.svg";
import phoneSvg from "assets/phone.svg";
import checkWhiteSvg from "assets/check-white.svg";
import {
  Footer,
  Header,
  InputContact,
  Notification,
  SelectContact,
  Textarea,
  SEO,
} from "components";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import { requestSupportV2 } from "state/Support/actions";
import { actionCreators, selectContactsState } from "state/Support/reducer";
import * as styles from "./Support.module.scss";

const subjectOptions = [
  "Order Issue",
  "Request Refund",
  "Exchange Product",
  "Address Change",
  "Login Issue",
  "Cancel Membership",
  "Concierge Service",
  "Other",
];

const defaultNotificationTitle = "Error";

const Support = (props) => {
  const {
    contactsState,
    requestSupportV2,
    menu,
    resetMenuFilters,
    setMenuCollection,
    data,
  } = props;

  const { shared, landing } = useSiteMetadata();

  const supportPhone = shared.SUPPORT_PHONE;
  const supportEmail = shared.SUPPORT_EMAIL;

  const orderStatusLink = data.markdownRemark?.frontmatter?.ORDER_STATUS_LINK;
  const chatLink = data.markdownRemark?.frontmatter?.CHAT_LINK;

  const [notificationTitle, setNotificationTitle] = useState(
    defaultNotificationTitle
  );
  const [notification, showNotification] = useState();
  const [isShowErrors, setIsShowErrors] = useState(false);

  const [emailSent, setEmailSent] = useState(false);
  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");
  const myRef = useRef();

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.scrollTo(0, 0);
    trackingGA();

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    setNotificationTitle(defaultNotificationTitle);
    showNotification(contactsState.UIState.error);
  }, [contactsState.UIState.error]);

  const handleSubmit = async () => {
    setIsShowErrors(true);
    if (contactsState.isValid) {
      const isSent = await requestSupportV2({ landingId: landing.LANDING_ID });
      if (isSent) {
        setNotificationTitle("Success");
        showNotification("Request was sent!\nWe will be with you shortly.");
        setEmailSent(true);
      }
    }
  };

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } =
    data?.markdownRemark?.frontmatter || {};

  return (
    <div className={styles.support}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname="/support"
      />
      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
        />
        <div className={styles.container}>
          <div className={styles.paragraph}>
            <div className={styles.title}>
              Welcome to Fan Made Fits Support Center
            </div>
            <div style={{ margin: 0 }} className={styles.description}>
              We’re dedicated to making sure your experience with us is fire.
            </div>
          </div>
          <div className={styles.paragraph}>
            <div className={styles.subtitle}>Quick Links</div>
            <div className={styles.descriptionGray}>
              <Link className={styles.link} to="/faq">
                FAQs
              </Link>
              &nbsp; - answers to our most commonly asked questions
            </div>
            <div className={styles.descriptionGray}>
              <Link className={styles.link} to="/membership">
                VIP Membership
              </Link>
              &nbsp; - benefits, rules and general information for VIP members
            </div>
            <div className={styles.descriptionGray}>
              <a
                className={styles.link}
                href={orderStatusLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Order Status
              </a>
              &nbsp; - track your package here
            </div>
            <div className={styles.descriptionGray}>
              <Link className={styles.link} to="/refund-policy">
                Return & Refund Policy
              </Link>
              &nbsp; - information on how to request refunds or returns.
            </div>
          </div>
          <div className={styles.thirdTitle}>
            If you have ANY questions regarding a recent order or any support
            inquiry, use the form below.
          </div>

          <div ref={myRef}>
            <div className={styles.inputContainer}>
              <InputContact
                label="* Tell us your email address for us to reply back to you"
                onChange={(value) => {
                  if (value !== contactsState.email) {
                    props.setEmail(value);
                    setEmailSent(false);
                  }
                }}
                isValid={contactsState.validation.email.isValid}
                isShowErrors={isShowErrors}
                errorMessage={contactsState.validation.email.error}
                defaultValue={contactsState.email}
                name="email"
                autoComplete="home email"
              />
            </div>
            <div className={styles.inputContainer}>
              <SelectContact
                placeholder="Please select a subject"
                label="* What best describes your inquiry?"
                onSelect={(value) => {
                  if (value?.value !== contactsState?.subject?.value) {
                    props.setSubject(value);
                    setEmailSent(false);
                  }
                }}
                isValid={contactsState.validation.subject}
                isShowErrors={isShowErrors}
                errorMessage="Please select a subject"
                value={contactsState.subject}
                options={subjectOptions}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputContact
                label="Order number (if you have one)"
                onChange={(value) => {
                  if (value !== contactsState.orderNumber) {
                    props.setOrderNumber(value);
                  }
                }}
                defaultValue={contactsState.orderNumber}
              />
            </div>
            <div className={styles.textareaContainer}>
              <Textarea
                label="Your message"
                defaultValue={contactsState.message}
                isValid={contactsState.validation.message}
                isShowErrors={isShowErrors}
                errorMessage="Please input your Message"
                onChange={(value) => {
                  if (value !== contactsState.message) {
                    props.setMessage(value);
                    setEmailSent(false);
                  }
                }}
                name="message"
              />
            </div>
          </div>

          <div className={styles.submitButtonContainer}>
            <button
              className={
                emailSent === true
                  ? styles.submitButtonSubmitted
                  : styles.submitButton
              }
              onClick={handleSubmit}
              disabled={contactsState.message.length === 0 || emailSent}
            >
              {emailSent === true ? (
                <>
                  <img
                    src={checkWhiteSvg}
                    alt=""
                    className={styles.checkWhite}
                  />
                  Submitted!
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>

          <div className={styles.subtitle}>Other Ways to Reach us</div>
          <div className={styles.descriptionGray}>
            We pride ourselves on fast responses and 24/7 support. We respond to
            inquiries in the order that they are received. Most are answered
            within 6 to 12 hours.
          </div>

          <div className={styles.benefitsContainer}>
            <div className={styles.benefit}>
              <img className={styles.benefitIcon} src={emailSvg} alt="" />
              <div className={styles.benefitTitle}>Email us</div>
              <div className={styles.benefitDescription}>
                Our email support is open 24/7. 365 days a year.{" "}
                <span className={styles.benefitDescription}>
                  <a className={styles.link} href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>
                </span>
              </div>
            </div>

            <div className={styles.benefit}>
              <img className={styles.benefitIcon} src={chatSvg} alt="" />
              <div className={styles.benefitTitle}>Chat with us</div>
              <div className={styles.benefitDescription}>
                Use Facebook messenger to chat with us. Usual response time is
                1-2 hours.{" "}
                <span className={styles.benefitDescription}>
                  {chatLink ? (
                    <a
                      className={styles.link}
                      href={chatLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      chat here
                    </a>
                  ) : (
                    "currently unavailable"
                  )}
                </span>
              </div>
            </div>

            <div className={styles.benefit}>
              <img className={styles.benefitIcon} src={phoneSvg} alt="" />
              <div className={styles.benefitTitle}>Call or text</div>
              <div className={styles.benefitDescription}>
                Our call center is temporarily closed. However, feel free to
                text our number to receive text support.
              </div>
              <div className={styles.benefitDescription}>
                <a href={`tel:${supportPhone}`} className={styles.link}>
                  {supportPhone}
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>

      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification message={notification} title={notificationTitle} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contactsState: selectContactsState(state),
  menu: selectMenu(state),
});

const mapDispatchToProps = {
  requestSupportV2,
  setEmail: actionCreators.setEmail,
  setOrderNumber: actionCreators.setOrderNumber,
  setSubject: actionCreators.setSubject,
  setMessage: actionCreators.setMessage,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);

export const query = graphql`
  query SupportPage {
    markdownRemark(fields: { slug: { eq: "/support/" } }) {
      frontmatter {
        ORDER_STATUS_LINK
        CHAT_LINK
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`;
